.oneTable{
  &.wait{
    opacity: 0.2;
  }
  table{
    width: 100%;
    margin: 0;
    padding: 0;
  }
  td, th{
    background: #FFFFFF;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 32px;
    height: 100%;
    text-align: center;
    border-color: var(--light-blue);
    border-style: solid;
    border-width: 1px;
    border-top: none;
    border-bottom: none;


  }
  th{
    font-size: 15px;
    font-weight: 400;
    &:first-of-type{
      border-radius: var(--main-border-radius) 0 0 var(--main-border-radius);
    }
    &:last-of-type{
      border-radius: 0 var(--main-border-radius) var(--main-border-radius) 0;
    }
  }
  &Item{
    position: relative;
    &Number{
      border-radius: var(--main-border-radius) 0 0 var(--main-border-radius);
      span{
        position: absolute;
        content: counter(item);
        counter-increment: item;
        color: var(--white);
        background: var(--light-grey);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 400;
      }
    }
    &Logo{
      img{
        border: 3px solid var(--dark-grey);
        border-radius: var(--main-border-radius);
        overflow: hidden;
        width: 59px;
        height: auto;
        @include breakpoint(large down) {
          width: 39px;
          height: auto;
        }
        @include breakpoint(small down) {
          width: 59px;
          height: auto;
        }
      }
    }
    &Bonus{
      color: var(--bright-blue);
      font-weight: 700;
      font-size: 17.5px;
      @include breakpoint(large down) {
        font-size: 16px;
      }
      @include breakpoint(small down) {
        font-size: 12.5px;
      }
    }
    &Lable{
      color: var(--dark-blue);
      font-size: 16.5px;
      font-weight: 700;
      @include breakpoint(medium down) {
        font-size: 14.5px;
      }
    }
    td.oneTableItemName{
      font-weight: 700;
      font-size: 22.5px;

      @include breakpoint(large down) {
        font-size: 16px;
      }
      @include breakpoint(small down) {
        display: none;
      }
    }
    td.oneTableItemSpellen{
      font-weight: 700;
      font-size: 22.5px;
      @include breakpoint(large down) {
        display: none;
      }
    }
    td.oneTableItemPayments {
      display: grid;
      grid-template-columns: repeat(3, 34px);
      grid-gap: 6px;
      justify-content: center;
      border-radius: 0 var(--main-border-radius) var(--main-border-radius) 0;
      @include breakpoint(large down) {
        display: none;
      }
      div{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img{
        border-radius: 3px;
        width: 34px;
        height: 22px;
        object-fit: cover;
      }
    }

    td.oneTableItemButton{
      @include breakpoint(large down) {
        a{
          padding: 7px 16px;
          font-size: 14px;
        }
      }
      @include breakpoint(small down) {
        display: none;
      }
      &Mobile{
        display: none;
        border-radius: 0 var(--main-border-radius) var(--main-border-radius) 0;
        @include breakpoint(small down) {
          display: flex;
          .button{
            padding: 5px 15px;
            &::after{
              content: "";
              display: block;
              width: 18px;
              height: 18px;
              background: url("data:image/svg+xml,%3Csvg width='9' height='13' viewBox='0 0 9 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.94531 6.85156L2.14844 12.6484C2.04948 12.7474 1.93229 12.7969 1.79688 12.7969C1.66146 12.7969 1.54427 12.7474 1.44531 12.6484L0.148438 11.3516C0.0494792 11.2526 0 11.1354 0 11C0 10.8646 0.0494792 10.7474 0.148438 10.6484L4.29688 6.5L0.148438 2.35156C0.0494792 2.2526 0 2.13542 0 2C0 1.86458 0.0494792 1.7474 0.148438 1.64844L1.44531 0.351562C1.54427 0.252604 1.66146 0.203125 1.79688 0.203125C1.93229 0.203125 2.04948 0.252604 2.14844 0.351562L7.94531 6.14844C8.04427 6.2474 8.09375 6.36458 8.09375 6.5C8.09375 6.63542 8.04427 6.7526 7.94531 6.85156Z' fill='white'/%3E%3C/svg%3E") no-repeat center center / contain;
            }
          }

        }
      }
    }
    td.oneTableItemPayout{
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      @include breakpoint(small down) {
        display: none;
      }
    }
    td.oneTableItemRtp{
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      @include breakpoint(small down) {
        display: none;
      }
    }
    td.oneTableItemBonusType{
      color: #757575;
      font-weight: 400;
      font-size: 17.5px;
      flex-direction: column;
      @include breakpoint(small down) {
        display: none;
      }
    }
    td.oneTableItemDescription{
      color: var(--bright-blue);
      font-weight: 700;
      font-size: 22.5px;
      flex-direction: column;
      @include breakpoint(medium down) {
        font-size: 17.5px;
      }
      @include breakpoint(small down) {
        display: none;
      }
    }
    td.oneTableItemBonusName{
      font-weight: 700;
      font-size: 22.5px;
      display: flex;
      flex-direction: column;
      @include breakpoint(medium down) {
        font-size: 17.5px;
      }
      @include breakpoint(small down) {
        font-size: 15.5px;
      }
    }
  }

  &.standart{
    tr{
      display: grid;
      grid-template-columns: 47px 105px 359px 183px 234px 104px auto;
      margin-bottom: 10px;
      filter: drop-shadow(0px 8px 14px rgba(52, 105, 179, 0.1));
      border-radius: var(--main-border-radius);
      overflow: visible;
      @include breakpoint(large down) {
        grid-template-columns: 47px 105px auto 160px 190px;
      }
      @include breakpoint(small down) {
        grid-template-columns: 40px 85px auto 20%;
      }
    }
    td, th{
      border-width: 1px 1px 1px 0px;
      @include breakpoint(large down) {
        &:nth-last-child(2){
          display: none;
        }
        &:last-child{
          display: none;
        }
      }

    }
    th{
      @include breakpoint(large down) {
        &:nth-child(5){
          border-radius: 0 var(--main-border-radius) var(--main-border-radius) 0;
        }
      }
      @include breakpoint(small down) {
        &:nth-child(5){
          display: none;
        }
        &:nth-child(4){
          border-radius: 0 var(--main-border-radius) var(--main-border-radius) 0;
        }
      }
    }
  .oneTableItemName{
    @include breakpoint(large down) {
      border-radius: 0 var(--main-border-radius) var(--main-border-radius) 0;
    }
  }
  }
  &.withRtp{
    thead{
      tr{
        height: 32px;
        th:nth-child(1), th:last-child{
          border: none;
        }
        th:nth-child(8), th:last-child{
          @include breakpoint(large down) {
            display: none;
          }
        }
      }
      @include breakpoint(small down) {
        display: none;
      }
    }
    td{
      border-right: 1px solid var(--light-blue);
      font-weight: 700;
    }
    td:nth-child(1), td:last-child{
      border: none;
    }
    tr{
      filter: drop-shadow(0px 8px 14px rgba(52, 105, 179, 0.1));
      border-radius: var(--main-border-radius);
      height: 70px;
      display: grid;
      grid-template-columns: 47px 105px 195px 65px 95px 180px auto 100px 165px;
      margin-bottom: 10px;
      background-color: var(--white);
      align-items: center;
      position: relative;
      @include breakpoint(large down) {
        grid-template-columns: 45px 75px 200px 70px 95px 160px auto;
      }
      @include breakpoint(medium down) {
        grid-template-columns: 45px 75px 150px 70px 95px 160px auto;
      }
      @include breakpoint(small down) {
        height: auto;
        min-height: 50px;
        grid-template-columns: 35px 60px auto 70px;
      }
    }
    .oneTableItemName{
      @include breakpoint(large down) {
        border-radius: 0 var(--main-border-radius) var(--main-border-radius) 0;
      }
    }
  }

  &.bonuses{
    tr{
      display: grid;
      grid-template-columns: 47px 105px auto 250px 180px 160px 180px;
      margin-bottom: 10px;
      filter: drop-shadow(0px 8px 14px rgba(52, 105, 179, 0.1));
      border-radius: var(--main-border-radius);
      overflow: visible;
      @include breakpoint(large down) {
        grid-template-columns: 45px 75px auto 250px 150px 150px 150px;
      }
      @include breakpoint(medium down) {
        grid-template-columns: 45px 95px auto 165px 105px 105px 70px;
      }
      @include breakpoint(small down) {
        grid-template-columns: 35px 55px auto 65px;
      }
    }
    td.oneTableItemButton{
      @include breakpoint(large down) {
        a{
          padding: 7px 16px;
          font-size: 14px;
        }
      }
      @include breakpoint(medium down) {
        display: none;
      }
      &Mobile{
        display: none;
        @include breakpoint(medium down) {
          display: flex;
          .button{
            padding: 5px 15px;
            &::after{
              content: "";
              display: block;
              width: 18px;
              height: 18px;
              background: url("data:image/svg+xml,%3Csvg width='9' height='13' viewBox='0 0 9 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.94531 6.85156L2.14844 12.6484C2.04948 12.7474 1.93229 12.7969 1.79688 12.7969C1.66146 12.7969 1.54427 12.7474 1.44531 12.6484L0.148438 11.3516C0.0494792 11.2526 0 11.1354 0 11C0 10.8646 0.0494792 10.7474 0.148438 10.6484L4.29688 6.5L0.148438 2.35156C0.0494792 2.2526 0 2.13542 0 2C0 1.86458 0.0494792 1.7474 0.148438 1.64844L1.44531 0.351562C1.54427 0.252604 1.66146 0.203125 1.79688 0.203125C1.93229 0.203125 2.04948 0.252604 2.14844 0.351562L7.94531 6.14844C8.04427 6.2474 8.09375 6.36458 8.09375 6.5C8.09375 6.63542 8.04427 6.7526 7.94531 6.85156Z' fill='white'/%3E%3C/svg%3E") no-repeat center center / contain;
            }
          }

        }
      }
    }
  .oneTableItemButton{
    border-radius: 0 var(--main-border-radius) var(--main-border-radius) 0;
  }

  }

  &.bonusesShort{
      max-width: 904px;
      margin: 0 auto;
    tr{
      display: grid;
      margin-bottom: 10px;
      filter: drop-shadow(0px 8px 14px rgba(52, 105, 179, 0.1));
      border-radius: var(--main-border-radius);
      overflow: visible;
      grid-template-columns: 47px 105px auto 270px 200px;
      @include breakpoint(medium down) {
        grid-template-columns: 47px 75px auto 230px 150px;
      }
      @include breakpoint(small down) {
        grid-template-columns: 35px 55px auto 65px;
      }
    }
    td.oneTableItemButton{
      @include breakpoint(medium down) {
        display: flex;
      }
      @include breakpoint(small down) {
        display: none;
      }
      &Mobile{
        @include breakpoint(medium down) {
          display: none;
        }
        @include breakpoint(small down) {
          display: flex;
        }
      }
    }
    .oneTableItemButton{
      border-radius: 0 var(--main-border-radius) var(--main-border-radius) 0;
    }
  }
  &.small{
    thead{
      tr{
        height: 32px;
        position: relative;
        th{
          font-weight: 400;
        }
        th:nth-child(2), th:nth-child(4){
          border-right: 1px solid var(--light-blue);
        }
      }
      tbody{
        tr{
          height: 70px;
        }
      }
      @include breakpoint(small down) {
        display: none;
      }
    }
    tr{
      display: grid;
      margin-bottom: 10px;
      filter: drop-shadow(0px 8px 14px rgba(52, 105, 179, 0.1));
      border-radius: var(--main-border-radius);
      overflow: visible;
      grid-template-columns: 47px 105px auto 270px 200px;
      @include breakpoint(medium down) {
        grid-template-columns: 47px 75px auto 230px 150px;
      }
      @include breakpoint(small down) {
        grid-template-columns: 35px 55px auto 98px;
      }
    }
    td.oneTableItemButton{

      @include breakpoint(medium down) {
        display: flex;
      }
      @include breakpoint(small down) {
        display: none;
      }
      &Mobile{
        a{
          color: var(--black);
        }
        font-size: 13px;
        gap: 2px;
        text-decoration: underline;
        flex-direction: column;
        @include breakpoint(medium down) {
          display: none;
        }
        @include breakpoint(small down) {
          display: flex;
        }
      }
    }
    .oneTableItemName{
    border-radius: 0 var(--main-border-radius) var(--main-border-radius) 0;
  }
  }
  &.topAside{
    tr{
      height: 50px;
      display: grid;
      grid-template-columns: 41px 65px auto 60px;
      background-color: var(--light-white);
        border-radius: var(--main-border-radius);
        margin-bottom: 16px;
        align-items: center;
        text-align: center;
        border: 1px solid var(--light-blue);
      @include breakpoint(medium down) {
        grid-template-columns: 41px 130px auto 130px;
      }
      @include breakpoint(small down) {
        grid-template-columns: 41px 46px auto 60px;
        height: auto;
      }
    }

    td.oneTableItemBonus{
      font-size: 15.5px;
      font-weight: 700;
      border-right: 1px solid var(--light-blue);
      border-left: 1px solid var(--light-blue);
      color: var(--bright-blue);
      height: 100%;
    }
    td.oneTableItemLogo{
      border: 4px solid var(--dark-grey);
      overflow: hidden;
      border-radius: var(--main-border-radius);
      width: 39px;
      height: 39px;
      margin-left: 10px;
      img{
        min-width: 39px;
        height: 39px;
        object-fit: cover;
      }
      @include breakpoint(medium down) {
        margin: 0 auto;
      }
    }
    td.oneTableItemButton{
      .button{
        padding: 5px 14px;
        border-radius: var(--light-border-radius);
        &::after{
          content: "";
          display: block;
          width: 18px;
          height: 18px;
          background: url("data:image/svg+xml,%3Csvg width='9' height='13' viewBox='0 0 9 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.94531 6.85156L2.14844 12.6484C2.04948 12.7474 1.93229 12.7969 1.79688 12.7969C1.66146 12.7969 1.54427 12.7474 1.44531 12.6484L0.148438 11.3516C0.0494792 11.2526 0 11.1354 0 11C0 10.8646 0.0494792 10.7474 0.148438 10.6484L4.29688 6.5L0.148438 2.35156C0.0494792 2.2526 0 2.13542 0 2C0 1.86458 0.0494792 1.7474 0.148438 1.64844L1.44531 0.351562C1.54427 0.252604 1.66146 0.203125 1.79688 0.203125C1.93229 0.203125 2.04948 0.252604 2.14844 0.351562L7.94531 6.14844C8.04427 6.2474 8.09375 6.36458 8.09375 6.5C8.09375 6.63542 8.04427 6.7526 7.94531 6.85156Z' fill='white'/%3E%3C/svg%3E") no-repeat center center / contain;
        }
      }
      @include breakpoint(small down) {
        display: flex;
      }
    }
  }

  &.noHead{
    thead{
      display: none;
    }
  }


  .topElement{
    position: absolute;
    //background-color: transparent;
    //background-image: url('./../../src/assets/img/after_table_top.png');
    //background-repeat: no-repeat;
    //background-position: center;
    //background-size: 173px;
    background-color: #1672DF;
    color: var(--white);
    width: 173px;
    top: -9px;
    left: 12px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15px;
    border: none;
    z-index: 7;
    box-shadow: none;
    min-height: 15px;
    border-radius: 0;
    &:after{
      content: '';
      position: absolute;
      width: 158px;
      height: 100%;
      border-radius: 8px;
      background-color: #1672DF;
      bottom: -6px;
      z-index: -1;
    }
    @include breakpoint(small down) {
      left: 2px;
    }
  }

}