@import "../_variables.scss";

.header{
  padding: 0 20px;
  width: 100%;
  background-color: var(--dark-white);
  box-shadow: 0px 2px 2px var(--dark-white);
  position: sticky;
  left: 0;
  top: 0;
  z-index: 10;
  &Wrapper{
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &Right{
    margin-left: auto;
    display: flex;
    align-items: center;
    height: 100%;
    flex: 1;
    justify-content: flex-end;
  }
  &Logo{
    a{
      display: flex;
      align-items: center;
    }
    img{
      width: 120px;
      height: 41px;
    }
  }
  &Menu{
    margin-left: 44px;
    height: 100%;
    &Desc{
      height: 100%;
      display: flex;
      align-items: center;
    }
    a{
      color: var(--dark-blue);
      text-transform: uppercase;
    }
    &List{
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      height: 100%;
    }
    &Item{
      padding-right: 16px;
      padding-left: 8px;
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      svg{
        margin-bottom: 2px;
        margin-left: 5px;
      }
      ul{
        position: absolute;
        top: 100%;
        left: 0;
        list-style: none;
        background: var(--white);
        box-shadow: 0px 8px 14px rgba(132, 145, 164, 0.16);
        border-radius: var(--main-border-radius);
        padding: 12px 0;
        width: 100%;
        display: none;
        transition: all .3s;
        margin: 0;
        li{
          padding: 5px 12px;
          &.hasChildren {
            position: relative;
            ul{
              display: none;
            }
            &:hover{
              ul{
                display: block;
                left: 100%;
                top: 0;
              }
            }
            a{
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 2px;
              svg{
                min-width: 10px;
                transform: rotate(-90deg);
                margin-bottom: 0;
                margin-top: 2px;
              }
            }
          }
        }
        a{
          transition: all .3s;
          &:hover{
            text-decoration: underline;
          }
        }
      }
      &:hover{
        ul{
          display: block;
        }
      }
    }
    @include breakpoint(large down) {
      display: none;
    }
  }
  &Limitation{
    font-weight: 600;
    font-size: 20px;
    margin-right: 15px;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    &.hide{
      display: none;
    }
    &Helper{
      position: absolute;
      background: var(--white);
      box-shadow: 0px 8px 14px rgba(132, 145, 164, 0.16);
      border-radius: var(--main-border-radius);
      padding: 12px;
      font-size: 15px;
      font-weight: 400;
      width: 352px;
      top: 100%;
      right: 0;
      display: none;
      @include breakpoint(small down) {
        width: 290px;
        right: -100px;
      }
    }
    &:hover{
      .headerLimitationHelper{
        display: block;
      }
    }
  }
  &User{
    margin-right: 9px;
    position: relative;
    &Icon{
      border: none;
      background: transparent;
      position: relative;
      cursor: pointer;
      img{
        width: 40px;
        height: 40px;
        @include breakpoint(large down) {
          width: 30px;
          height: 30px;
        }
      }
    }
    &Helper{
      position: absolute;
      display: none;
      top: 100%;
      right: 0;
      box-shadow: 0px 8px 14px rgba(132, 145, 164, 0.14);
      border-radius: var(--main-border-radius);
      padding: 12px 12px 17px 12px;
      font-size: 15px;
      font-weight: 400;
      width: 300px;
      text-align: center;
      background: var(--white);
      @include breakpoint(small down) {
        width: 290px;
        right: -40px;
      }
  }
    &Close{
      display: block;
      margin-left: auto;
      border: none;
      background: transparent;
      cursor: pointer;
    }
    &Text{
      padding: 0 4px;
      font-size: 16px;
      margin: 10px 0 15px;
    }
    &Button{
      padding: 7px 0;
      text-align: center;
      min-width: 223px;
      cursor: pointer;
      margin-bottom: 15px;
    }
    &.open{
      .headerUserHelper{
        display: block;
      }
    }
    @include breakpoint(large down) {
      margin-right: 0;
    }
  }
  &Lang {
    position: relative;
    height: 100%;
    button{
      height: 100%;
      border: none;
      background: transparent;
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      img{
        width: 40px;
        height: 40px;
        margin-right: 6px;
      }
    }
    ul {
      position: absolute;
      top: 99%;
      left: 10px;
      list-style: none;
      display: none;
      padding: 6px 0;
      margin: 0;
    }
    &:hover{
      ul{
        display: block;
      }
    }
    &Item{
      margin-bottom: 8px;
    }
    &Link{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @include breakpoint(large down) {
      display: none;
    }
  }
}


#search {
  display: flex;
  justify-content: flex-end;
  margin-left: 0;
  height: 34px;
  border-radius: var(--light-border-radius);
  transition: width 0.15s;
  padding: 0 9px;
  width: 20px;
  position: relative;
  margin-left: 10px;
  @media(max-width: 375px){
    display: none;
  }
  form {
    width: 100%;
  }
  input {
    display: none;
    outline: none;
    font-size: 16px;
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
  }
  button {
    all: unset;
    box-sizing: border-box;
    width: 20px;
    min-width: 20px;
    height: 100%;
    display: block;
    position: relative;
    cursor: pointer;
    &::after {
      position: absolute;
      content: '';
      top: 50%;
      right: 0;
      width: 20px;
      height: 20px;
      background: url("data:image/svg+xml,%3Csvg width='27' height='26' viewBox='0 0 27 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.125 24.625L19.5062 19.0062M22.5417 11.7083C22.5417 17.4153 17.9153 22.0417 12.2083 22.0417C6.50139 22.0417 1.875 17.4153 1.875 11.7083C1.875 6.00139 6.50139 1.375 12.2083 1.375C17.9153 1.375 22.5417 6.00139 22.5417 11.7083Z' stroke='%231F2238' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat center center / contain;
      cursor: pointer;
      transform: translateY(-50%);
      z-index: 1;
    }
  }

  &.open {
    width: calc(100% - 135px);
    background: var(--white);
    @include breakpoint(large down) {
      width: calc(100% - 85px);
    }
    input {
      display: inline-block;
    }
    button {
      &::after {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20.6422' height='1.73557' rx='0.867787' transform='matrix(0.70671 0.707504 -0.63753 0.770426 1.10742 0.0292969)' fill='%23757575'/%3E%3Crect width='20.6499' height='1.73493' rx='0.867465' transform='matrix(-0.703897 0.710302 0.64053 0.767933 14.8906 0)' fill='%23757575'/%3E%3C/svg%3E") no-repeat center center / contain;
      }
    }
  }
}
.searchList {
  position: absolute;
  bottom: 0px;
  left: 0;
  transform: translateY(100%);
  background: inherit;
  list-style: none;
  padding: 9px;
  margin: 0;
  width: 100%;
  &.none{
    display: none;
  }
  .searchListItem {
    border-top: 1px solid var(--light-blue);
    padding-top: 6px;
    &:not(last-child) {
      padding-bottom: 6px;
    }
  }
  a {
    font-size: 16px;
    line-height: 29px;
    color: var(--secondary-dark-color);
    text-decoration: none;
  }
}


.burger {
  display: none;
  position: relative;
  padding:0 0 0 12px;
  background: transparent;
  border: 1px solid transparent;
  margin: 7px 0;
  z-index: 5;
  cursor: pointer;
  &:focus {
    outline-width: 0;
  }
  & [class*='bar-'] {
    background: #000;
    display: block;
    border-radius: 10px;
    height: 2px;
    width: 20px;
    margin-bottom: 5px;
  }
  .bar-bot {
    margin-bottom: 0;
  }
  &.opened {
    .bar-top {
      background: #757575;
      transform: rotate(45deg);
      transform-origin: 0px 2px;
    }
  }
  &.opened {
    .bar-mid {
      opacity: 0;
    }
  }
  &.opened {
    .bar-bot {
      background: #757575;
      transform: rotate(-45deg);
      transform-origin: 0px 1px;
    }
  }
  @include breakpoint(large down) {
    display: block;
  }
}

.off-canvas{
  position: absolute;
  width: calc(100% - 26px);
  padding-bottom: 100px;
  right: 0;
  top: 100%;
  background-color: var(--white);
  border-radius: var(--light-border-radius);
  box-shadow: 0px 8px 14px rgba(132, 145, 164, 0.16);
  transform: translateY(-150%);
  transition: all .3s;
  overflow: auto;
  min-height: 70vh;
  z-index: 25;
  height: 100%;
  &.opened{
    transform: translateY(0);
  }
  ul{
    padding: 0 12px;
    margin: 0;
    list-style: none;
  }
  .headerMenuMobile{
    &List{
      padding: 12px;
    }
    &Button{
      background: transparent;
      transition: all .3s;
      border: none;
      cursor: pointer;
      display: flex;
      margin: 0;
      padding:4px 12px;
      justify-content: center;
      align-items: center;
      &.open{
        transform: rotate(180deg);
      }
    }
    &Item{
      padding: 0 0 12px 0;
      &Box{
        display: flex;
        align-items: center;
      }
      &:not(:first-child){
        padding: 12px 0;
        border-top: 1px solid var(--light-blue);
      }
      a{
        color: var(--dark-blue);
        font-weight: 400;
        font-size: 16px;
        text-transform: uppercase;

      }
      ul{
        max-height: 0;
        transition: all .5s;
        overflow: hidden;
        li:first-child{
          padding-top: 12px;
        }
        li:not(:last-child){
          padding-bottom: 12px;
        }
        a{
          font-size: 14px;
        }
      }
      &.open{
        ul{
          max-height: 2000px;
          li{
            ul{
              max-height: 0;
            }
            &.open{
              ul{
                max-height: 2000px;
              }
            }
          }
        }
      }
    }
  }
}