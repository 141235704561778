.sitemap{
  padding-bottom: 14px;
  @include breakpoint(medium down) {
    padding-bottom: 0;
  }
  .breadcrumbs{
    margin: 0 40px 16px 0;
    @include breakpoint(medium down) {
      margin: 0 0 16px 0;
    }
    ul{
      margin: 0;
    }
    .container{
      padding: 0;
    }
  }
  &Wrapper{
    display: grid;
    grid-template-columns: auto 350px;
    gap: 24px;
    @include breakpoint(medium down) {
      grid-template-columns: 1fr;
      gap: 9px;
    }
  }
  &Element{
    background: var(--white);
    box-shadow: 0px 8px 45px rgba(52, 105, 179, 0.1);
    border-radius: var(--main-border-radius);
    margin-bottom: 16px;
    padding: 20px;
  }
  &Items{
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    padding: 0;
    margin: 0;
  }
  &Item{
    font-size: 14px;
    padding-bottom: 10px;
    margin-left: 17px;
  }
}