.game {
  h1 {
    margin-bottom: 4px;
  }

  &Wrapper {
    display: grid;
    grid-template-areas:  "content gameAside"
                        "bottom bottom";
    gap: 20px;
    @include breakpoint(medium down) {
      grid-template-areas:  "content"
                            "bottom"
                            "gameAside";
      grid-template-columns: 100%;
    }
  }

  &Content {
    grid-area: content;
  }

  &Bottom {
    grid-area: bottom;
  }

  &Aside {
    grid-area: gameAside;
    width: 350px;
    min-width: 350px;
    @include breakpoint(medium down) {
      width: 100%;
      min-width: 100%;
    }
  }


  .meta {
    padding-bottom: 5px;
    @include breakpoint(small down) {
      display: none;
    }

    &Box {
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 13px;
      width: 100%;
      flex-wrap: wrap;
    }

    &Rating {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      margin-right: 12px;

      .stars,
      .post-ratings {
        margin-right: 10px;
        margin-bottom: -5px;
        width: max-content;
      }

      .post-ratings > img {
        width: 16px;
        height: 16px;
      }
    }

    &InfoGame {
      margin-left: auto;
      margin-right: 0;
      gap: 14px;

      &Item {
        display: flex;
        align-items: center;
        gap: 2px;
        color: #757575;
        cursor: pointer;
        border: none;
        background: transparent;
        padding: 0;

        &::after {
          content: "";
          display: block;
          margin-top: 2px;
        }
      }

      &YouTube {
        &::after {
          width: 12px;
          height: 11px;
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='512' height='512' viewBox='0 0 24 24'%3E%3Cpath fill='%23757575' d='M.522 17.874c.49 1.738 1.989 2.056 2.089 2.117 2.467.672 16.295.674 18.799 0 1.715-.496 2.03-2.017 2.089-2.117.653-3.474.696-8.003-.03-11.945l.03.196c-.49-1.738-1.989-2.056-2.089-2.117-2.434-.661-16.298-.686-18.799 0C.896 4.505.581 6.025.522 6.125c-.699 3.651-.734 7.84 0 11.749zm9.086-2.223V8.358l6.266 3.652z'/%3E%3C/svg%3E") no-repeat center center / contain;
        }
      }

      &Problem {
        &::after {
          width: 12px;
          height: 11px;
          background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.75993 9.20731V7.93499C6.75993 7.87249 6.73872 7.82003 6.69631 7.77762C6.6539 7.73521 6.60368 7.71401 6.54564 7.71401H5.25993C5.20189 7.71401 5.15167 7.73521 5.10926 7.77762C5.06685 7.82003 5.04564 7.87249 5.04564 7.93499V9.20731C5.04564 9.26981 5.06685 9.32227 5.10926 9.36468C5.15167 9.40709 5.20189 9.42829 5.25993 9.42829H6.54564C6.60368 9.42829 6.6539 9.40709 6.69631 9.36468C6.73872 9.32227 6.75993 9.26981 6.75993 9.20731ZM6.74654 6.70285L6.86707 3.62919C6.86707 3.57561 6.84475 3.5332 6.80011 3.50195C6.74207 3.45285 6.6885 3.42829 6.63939 3.42829H5.16618C5.11707 3.42829 5.0635 3.45285 5.00546 3.50195C4.96082 3.5332 4.9385 3.58008 4.9385 3.64258L5.05234 6.70285C5.05234 6.74749 5.07466 6.78432 5.1193 6.81334C5.16395 6.84235 5.21752 6.85686 5.28002 6.85686H6.51886C6.58136 6.85686 6.63381 6.84235 6.67622 6.81334C6.71863 6.78432 6.74207 6.74749 6.74654 6.70285ZM6.65279 0.448381L11.7956 9.87695C11.9519 10.1582 11.9474 10.4395 11.7823 10.7207C11.7064 10.8502 11.6026 10.9528 11.4709 11.0287C11.3392 11.1046 11.1974 11.1426 11.0456 11.1426H0.759928C0.608143 11.1426 0.466402 11.1046 0.334705 11.0287C0.203009 10.9528 0.0992141 10.8502 0.0233212 10.7207C-0.141857 10.4395 -0.146322 10.1582 0.00992839 9.87695L5.15279 0.448381C5.22868 0.309989 5.33359 0.200614 5.46752 0.120256C5.60145 0.0398998 5.74654 -0.000279427 5.90279 -0.000279427C6.05904 -0.000279427 6.20412 0.0398998 6.33805 0.120256C6.47198 0.200614 6.57689 0.309989 6.65279 0.448381Z' fill='%23757575'/%3E%3C/svg%3E") no-repeat center center / contain;
        }
      }

      &Fullscreen {
        &::after {
          width: 11px;
          height: 11px;
          background: url("data:image/svg+xml,%3Csvg width='11' height='12' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.61886 3.62249L6.24163 5.99972L8.61886 8.37695L9.58315 7.41267C9.71261 7.27427 9.86886 7.24302 10.0519 7.31892C10.226 7.39481 10.3131 7.52651 10.3131 7.71401V10.714C10.3131 10.8301 10.2706 10.9305 10.1858 11.0153C10.101 11.1002 10.0006 11.1426 9.88449 11.1426H6.88449C6.69699 11.1426 6.56529 11.0533 6.4894 10.8747C6.4135 10.7006 6.44475 10.5466 6.58315 10.4127L7.54743 9.44838L5.1702 7.07115L2.79297 9.44838L3.75725 10.4127C3.89565 10.5466 3.9269 10.7006 3.851 10.8747C3.77511 11.0533 3.64342 11.1426 3.45592 11.1426H0.455915C0.339844 11.1426 0.239397 11.1002 0.154576 11.0153C0.0697545 10.9305 0.0273438 10.8301 0.0273438 10.714V7.71401C0.0273438 7.52651 0.116629 7.39481 0.295201 7.31892C0.469308 7.24302 0.623326 7.27427 0.757254 7.41267L1.72154 8.37695L4.09877 5.99972L1.72154 3.62249L0.757254 4.58677C0.672433 4.6716 0.571987 4.71401 0.455915 4.71401C0.402344 4.71401 0.348772 4.70285 0.295201 4.68052C0.116629 4.60463 0.0273438 4.47294 0.0273438 4.28544V1.28543C0.0273438 1.16936 0.0697545 1.06892 0.154576 0.984096C0.239397 0.899275 0.339844 0.856864 0.455915 0.856864H3.45592C3.64342 0.856864 3.77511 0.94615 3.851 1.12472C3.9269 1.29883 3.89565 1.45285 3.75725 1.58677L2.79297 2.55106L5.1702 4.92829L7.54743 2.55106L6.58315 1.58677C6.44475 1.45285 6.4135 1.29883 6.4894 1.12472C6.56529 0.94615 6.69699 0.856864 6.88449 0.856864H9.88449C10.0006 0.856864 10.101 0.899275 10.1858 0.984096C10.2706 1.06892 10.3131 1.16936 10.3131 1.28543V4.28544C10.3131 4.47294 10.226 4.60463 10.0519 4.68052C9.99386 4.70285 9.93806 4.71401 9.88449 4.71401C9.76842 4.71401 9.66797 4.6716 9.58315 4.58677L8.61886 3.62249Z' fill='%23757575'/%3E%3C/svg%3E") no-repeat center center / contain;
        }
      }
    }

    .mobile {
      display: none;
      @include breakpoint(large down) {
        display: flex;
      }
    }

    .desc {
      display: flex;
      @include breakpoint(large down) {
        display: none;
      }
    }

    &.withoutInfo {
      .metaInfoGame {
        display: none;
      }
    }

  }

  .breadcrumbs {
    &List {
      margin-top: 0;
    }

    .container {
      padding: 0;
    }
  }

  .author {
    .container {
      padding: 0;
    }
  }

  .share {
    .container {
      padding: 0;
    }
  }
}

