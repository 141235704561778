@import './settings';
@import './mixins/index';

:root {
  // Fonts
  --body-font-family: 'Commissioner', sans-serif;
  --body-font-size: 16px;
  --body-line-height: 1.35;
  // Headers
  @each $bp, $header in $header-styles {
    @if $bp == 'small' {
      @each $selector, $styles in $header {
        @each $name, $val in $styles {
          --#{$selector}-#{$name}: #{$val};
        }
      }
    } @else {
      @include breakpoint($bp) {
        @each $selector, $styles in $header {
          @each $name, $val in $styles {
            --#{$selector}-#{$name}: #{$val};
          }
        }
      }
    }
  }
  // Palette
  --black: #171A29;
  --bright-blue: #1672DF;
  --light-blue: #E7F2FF;
  --medium-blue: #08274B;
  --dark-blue: #1F2238;
  --light-grey: #96A0AD;
  --medium-grey: #3B3F58;
  --dark-grey: #383A44;
  --white: #fff;
  --light-white: #F9FCFF;
  --medium-white: #F6FBFF;
  --dark-white: #EFF5FA;
  --red: #FF3D3E;

  --primary-color: #0057B7;
  --primary-dark-color: #044A9D;
  --primary-light-color: #1672DF;
  --primary-hover-color: #0F64C9;
  --on-primary-color: #fff;
  --secondary-color: #929CA9;
  --secondary-dark-color: #757575;
  //--secondary-light-color: #1365c5;
  --on-secondary-color: #fff;

  // Colors
  --body-color: var(--dark-blue);
  --body-background: var(--medium-white);
  --title-color: var(--bright-blue);
  --button-color: var(--primary-dark-color);
  --hover-button-color: var(--primary-color);
  --shadow-button-color: #022856;
  --disbutton-color: var(--secondary-color);
  --shadow-disbutton-color: #868F9A;
  --howto-background: var(--white);
  --table-background: var(--light-white);
  --header-cards-background: var(--dark-white);

  // Borders
  --main-border-radius: 10px;
  --light-border-radius: 4px;

  // Sizes
  --gap: 40px;
  --content-width: 440px;
  --container-space: 20px;
  --container-width: calc(var(--content-width) + var(--container-space) * 2);
  --section-space: 25px;

  // set content width breakpoint size
  @each $name, $val in $breakpoints {
    @if $name != 'small' {
      @include breakpoint($name) {
        --content-width: #{$val}px;
      }
    }
  }

  @include breakpoint(medium) {
    --section-space: 25px;
  }

  @include breakpoint(large) {
    --section-space: 25px;
  }
}
