.breadcrumbs{
ul{
  display: flex;
  list-style: none;
  gap: 10px;
  padding: 0;
  margin: 36px 0 12px;
  flex-wrap: wrap;
}
  li{
    color: var(--dark-blue);
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 7px;
    &:not(:last-child){
      &::after{
        content: "";
        display: block;
        width: 8px;
        height: 10px;
        background: url("data:image/svg+xml,%3Csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.97902 0.631987L5.22916 3.88652C5.28472 3.94208 5.3125 4.00787 5.3125 4.0839C5.3125 4.15992 5.28472 4.22571 5.22916 4.28127L1.97902 7.5358C1.92346 7.59136 1.85694 7.61914 1.77945 7.61914C1.70196 7.61914 1.63544 7.59136 1.57988 7.5358L0.856161 6.8077C0.800603 6.75214 0.772824 6.68635 0.772824 6.61032C0.772824 6.5343 0.800603 6.4685 0.856161 6.41295L3.18521 4.0839L0.856161 1.75484C0.800603 1.69929 0.772824 1.63349 0.772824 1.55747C0.772824 1.48144 0.800603 1.41565 0.856161 1.36009L1.57988 0.631987C1.63544 0.576429 1.70196 0.54865 1.77945 0.54865C1.85694 0.54865 1.92346 0.576429 1.97902 0.631987Z' fill='%23757575'/%3E%3C/svg%3E") no-repeat center center / contain;
        margin-bottom: -4px;
        margin-left: 2px;
      }
    }
    a{
      color: inherit;
    }
  }
  &WithBg{
    ul{
      background: var(--light-white);
      border: 1px solid var(--light-blue);
      border-radius: 6px;
      padding: 6px 6px 6px 16px;
    }
  }
}