.table{
  table{
    width: 100%;
  }
  &Content{
    margin-bottom: 20px;
  }
  &Button{
    text-align: center;
  }
  &More{
    @include breakpoint(large down) {
      font-size: 18px;
    }
  }


  &.onlyTable{
    .top3{
      display: none;
    }
    h2{
      display: none;
    }
  }
}

.tablePlay{
  position: relative;
  &.top3BonusMore {
    text-align: center;
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    width: 100%;
    margin: 0;

    & > a,
    & > span{
      border-bottom: 1px dotted;
      cursor: pointer;
      text-decoration: dotted;
      color: #6589b4;
    }

    & .tablePlayText {
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
  & > a,
  & > span{
    cursor: pointer;
    color: #757575;
    text-decoration: underline;
  }
  font-size: 13px;
  text-align: end;
  margin-bottom: 6px;
  &.single {
    text-align: center;
    width: max-content;
    margin: 10px auto;
  }
  &Text{
    display: block;
    position: absolute;
    right: 0;
    top: calc(100% + 5px);
    border-radius: var(--main-border-radius);
    padding: 12px;
    font-size: 12px;
    background-color: var(--white);
    font-weight: 400;
    max-width: 200px;
    z-index: 8;
    box-shadow: 0px 8px 14px rgba(132, 145, 164, 0.16);
    text-align: start;
    &.hide{
      display: none;
    }
  }
}

