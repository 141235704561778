.landing {
    .meta {
        padding: 0;

        &Box {
            display: flex;
            align-items: center;
            margin-bottom: 13px;
            width: 100%;
            flex-wrap: wrap;

            &:last-child {
                margin-bottom: 5px;
            }

            @include breakpoint(small down) {
                margin-bottom: 0;
            }
        }

        &Item {
            @include breakpoint(small down) {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        &Date {
            margin-left: auto;
            font-size: 13.5px;
            @include breakpoint(medium down) {
                margin-left: auto;
                flex: inherit;
            }
            @include breakpoint(small down) {
                display: none;
            }

            &.mobile {
                display: none;
                @include breakpoint(small down) {
                    display: flex;
                }
            }
        }

        &Inner {
            margin-left: 0;
            @include breakpoint(small down) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-top: 6px;
            }
        }

        &Rating {
            display: flex;
            align-items: center;
            margin-right: 12px;

            &Info {
                display: flex;
                align-items: center;
            }

            &Label {
                margin-right: 3px;
            }

            @include breakpoint(small down) {
                margin-bottom: 10px;
            }
        }

        .stars,
        .post-ratings {
            margin-right: 10px;
            margin-bottom: -5px;
            width: max-content;
        }

        .post-ratings > img{
            width: 16px;
            height: 16px;
        }

        strong {
            color: var(--bright-blue);
            font-weight: 700;
        }

        &Fact {
            @include breakpoint(small down) {
                min-width: auto;
            }
        }
    }
}

.lp-hero-img {
  display: none;

  @include breakpoint(large) {
    display: flex;
    float: right;
    margin: 0 0 20px 20px;
  }

  img {
    border-radius: 6px;
  }
}