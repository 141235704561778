.iframe{
  &Box{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 16 / 9;
  }
  &Img{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.hide{
      display: none;
    }
  }
  &Buttons{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 7px;
    z-index: 3;
    &.hide{
      display: none;
    }
    @include breakpoint(small down) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  &Real{
    padding: 0;
    height: 35px;
    font-size: 18px;
    width: 173px;
    @include breakpoint(small down) {
      height: 30px;
      font-size: 14px;
      width: 143px;
    }
  }
  &Free{
    font-size: 18px;
    padding: 0;
    height: 35px;
    width: 130px;
    @include breakpoint(small down) {
      height: 30px;
      font-size: 14px;
      width: 109px;
    }
  }
  &NotAvail{
    background: var(--disbutton-color);
    font-size: 14px;
    opacity: 0.9;
    border-radius: var(--main-border-radius);
    padding: 5px 10px;
    width: max-content;
    max-width: 300px;
    text-transform: uppercase;
    color: var(--on-primary-color);
    @include breakpoint(small down) {
      font-size: 12px;
    }
  }
  &Bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--dark-blue);
    opacity: 0.5;
    z-index: 2;
    &.hide{
      display: none;
    }
  }
  iframe{

    height: 100%;
    width: 100%;
    &.fullScreen{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &Link{
    margin-top: 13px;
    button{
      width: 100%;
    }
  }
}


