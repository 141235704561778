@import './settings';

* {
  box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--light-grey);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--light-grey);
}

body {
  margin: 0;
  padding: 0;
  font: {
    family: var(--body-font-family);
    size: var(--body-font-size);
  }
  line-height: var(--body-line-height);
  color: var(--body-color);
  background: var(--body-background);
  overflow-x: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin: 0 0 1em 0;
}

@include allHeaders {
  font-weight: 400;
  margin: 0 0 15px;
  text-transform: uppercase;
  text-align: left;
  color: var(--bright-blue);
  &.in-center {
    text-align: center;
  }
  &.no-transform {
    text-transform: none;
  }
  &.bold {
    font-weight: 700;
  }
  &.mobile-line {
    @include breakpoint(small down) {
      text-align: start;
      padding-bottom: 10px;
      border-bottom: 1px solid var(--secondary-color);
    }
  }
}
h1.withLine {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--secondary-color);
}

$headers: map-get($header-styles, 'small');

@each $selector, $options in $headers {
  #{$selector} {
    @each $option, $val in $options {
      #{$option}: var(--#{$selector}-#{$option});
    }
  }
}

a {
  color: var(--primary-light-color);
  text-decoration: none;

  &:hover {
    color: var(--primary-hover-color);
  }
}

.container {
  max-width: var(--container-width);
  padding-left: var(--container-space);
  padding-right: var(--container-space);
  margin-left: auto;
  margin-right: auto;
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

.section {
  margin-top: var(--section-space);
  margin-bottom: var(--section-space);

  .inner {
    padding-top: var(--section-space);
    padding-bottom: var(--section-space);
  }

  &.no-out-space {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.no-top-space {
    margin-top: 0;
  }

  &.no-bottom-space {
    margin-bottom: 0;
  }

  &Mobile {
    display: none;
    @include breakpoint(medium down) {
      display: block;
    }
  }
}

h1 {
  color: var(--dark-blue);
}

.none {
  display: none !important;
}
//wordpress default classes
.alignleft {
  float: left;
  margin-right: 24px;
}
.alignright {
  float: right;
  margin-left: 24px;
}
.aligncenter {
  float: none;
  margin: 0 24px;
}
.alignright,
.alignleft,
.aligncenter {
  @include breakpoint(small down) {
    float: none;
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 12px;
  }
}
//list-style
ul:not([class]) {
  padding-left: 30px;
  margin: 15px 0;
  li:not(:last-child) {
    margin-bottom: 8px;
  }
  li::marker {
    color: var(--bright-blue);
    width: 7px;
    height: 7px;
  }
}

.markTitle {
  text-align: start;
  text-transform: none;
  color: var(--bright-blue);
  margin-bottom: 12px;
  &::before {
    content: '✔  ';
    color: var(--dark-blue);
    font-size: 24px;
  }
}

//default table style
.defaultTable {
  max-width: 100%;
  overflow: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: start;
  &.text-center {
    text-align: center;
  }
  &.verticalHead {
    tr {
      td:first-child {
        color: var(--primary-dark-color);
        font-weight: 700;
        @include breakpoint(small down) {
          min-width: 160px;
        }
      }
    }
  }
  table:not([class]) {
    text-align: inherit;
    background: var(--light-blue);
    border: 1px solid var(--light-blue);
    box-shadow: 0px 8px 14px rgba(52, 105, 179, 0.1);
    border-radius: 15px;
    overflow: hidden;
    @include breakpoint(small down) {
      min-width: 440px;
    }
    th {
      color: var(--primary-dark-color);
      font-weight: 700;
    }
    td,
    th {
      background: var(--white);
      border: 1px solid var(--light-blue);
      padding: 8px 24px;
    }
  }
  &.fullwidth {
    table {
      width: 100%;
    }
  }
  &.verticalOnly {
    thead {
      display: none;
    }
  }
}

.error404 {
  text-align: center;
}

.limitedWidth {
  max-width: 933px;
}
