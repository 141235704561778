.slots{
  margin: 25px 0;
  &Items{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 17px;
    @include breakpoint(small down) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &Item{
    background: var(--white);
    border: 1px solid var(--light-blue);
    box-shadow: 0px 8px 14px rgba(52, 105, 179, 0.1);
    border-radius: var(--main-border-radius);
    overflow: hidden;
  }
  &Img{
    img{
      object-fit: cover;
      width: 100%;
    }
  }
  &Name{
    color: var(--dark-blue);
    padding: 9px 5px 7px 5px;
    text-align: center;
    text-transform: uppercase;
    font-size: 12.5px;
  }
}