.blogs{
  //padding-bottom: 139px;
  @include breakpoint(medium down) {
    padding-bottom: 0;
  }
  h1{
    margin-right: 31px;
    @include breakpoint(medium down) {
      font-size: 24px;
      margin-right: 0;
    }
  }
  .breadcrumbs{
    margin: 0 40px 16px 0;
    @include breakpoint(medium down) {
      margin: 0 0 16px 0;
    }
    ul{
      margin: 0;
    }
    .container{
      padding: 0;
    }
  }
&Wrapper{
  display: grid;
  grid-template-columns: auto 350px;
  gap: 9px;
  @include breakpoint(medium down) {
    grid-template-columns: 100%;
    gap: 16px;
  }
}
  &Item{
    margin-bottom: 16px;
    @include breakpoint(medium down) {
      margin-bottom: 25px;
    }
    h2{
      margin-bottom: 4px;
      @include breakpoint(medium down) {
        font-size: 20px;
      }
    }
    .meta{
      padding: 0 0 16px 0;
      @include breakpoint(small down) {
        &Item{
          margin-bottom: 0;
        }
        &Item:last-child{
          display: none;
        }
      }
    }
    &Inner{
      display: grid;
      grid-template-columns: 180px auto;
      gap: 16px;
      @include breakpoint(medium down) {
        grid-template-columns: 1fr;
        gap: 14px;
      }
    }
    &Logo{
      overflow: hidden;
      img{
        border-radius: 6px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.blog{
  h1{
    margin-right: 40px;
    @include breakpoint(medium down) {
      margin-right:0;
    }
  }
  .breadcrumbs{
    margin: 0 40px 16px 0;
    @include breakpoint(medium down) {
      margin: 0 0 16px 0;
    }
    ul{
      margin: 0;
    }
    .container{
      padding: 0;
    }
  }
  &Wrapper{
    display: grid;
    grid-template-columns: auto 350px;
    gap: 9px;
    @include breakpoint(medium down) {
      grid-template-columns: 100%;
      gap: 16px;
    }
  }
  &Hero{
    display: grid;
    grid-template-columns: 332px auto;
    gap: 24px;
    @include breakpoint(small down) {
      grid-template-columns: 1fr;
      gap: 16px;
    }
    &Img{
      overflow: hidden;
      width: 100%;
      text-align: center;
      img{
        border-radius: var(--main-border-radius);
       object-fit: cover;
        max-width: 100%;
      }
      &Float {
        width: max-content;
        float: left;
        margin: 0 1em 0.5em 0;
      }
    }
  }
  .meta{
    margin-right: 40px;
    padding-bottom: 13px;
    padding-top: 0;
    @include breakpoint(medium down) {
      margin-right:0;
    }
    @include breakpoint(small down) {
      padding-bottom: 42px;
    }
    &Item{
      @include breakpoint(small down) {
        margin-bottom: 0;
      }
    }
    &Box{
      display: flex;
      align-items: center;
      margin-bottom: 13px;
      width: 100%;
      flex-wrap: wrap;
      @include breakpoint(small down) {
        margin-bottom: 0;
      }
    }
    &Date{
      margin-left: auto;
      font-size: 13.5px;
      @include breakpoint(medium down) {
        margin-left: auto;
        flex: inherit;
      }
      @include breakpoint(small down) {
        display: none;
      }
      &.mobile{
        display: none;
        @include breakpoint(small down) {
          display: flex;
        }
      }
    }
    &Inner{
      margin-left: 0;
      @include breakpoint(small down) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 16px;
      }
    }
    &Rating{
      display: flex;
      align-items: center;
      margin-right: 12px;
      &Info{
        display: flex;
        align-items: center;
      }
      &Label{
        margin-right: 3px;
      }
      @include breakpoint(small down) {
        margin-bottom: 10px;
      }
    }
    .stars,
    .post-ratings {
      margin-right: 10px;
      margin-bottom: -5px;
      width: max-content;
    }

    .post-ratings > img{
      width: 16px;
      height: 16px;
    }
    strong{
      color: var(--bright-blue);
      font-weight: 700;
    }
    &Fact{
      @include breakpoint(small down) {
        min-width: auto;
      }
    }
  }
}

