.filterTable {
    &Box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        @include breakpoint(medium down) {
            display: none;
        }
    }

    &Sort {
        position: relative;

        &List {
            list-style: none;
            padding: 0 0 0 10px;
            margin: 10px 0;
        }

        &Item {
            margin-bottom: 5px;

            label {
                color: var(--bright-blue);
                display: flex;
                align-items: center;
            }

            input {
                display: inline-block;
                position: relative;
                width: 16px;
                height: 16px;
                margin-right: 4px;
                padding: 7px;
                background-color: transparent;
                color: rgb(170, 170, 170);
                border: 1px solid var(--light-blue);
                border-radius: 2px;
                vertical-align: top;
                outline: none;
            }
        }

        &Buttons {
            display: flex;
            justify-content: space-between;

            button {
                width: 48%;
                font-size: 15px;
            }
        }

        &Search {
            padding: 8px 16px 8px 41px;
            height: inherit;
            line-height: inherit;
            margin: 0;
            outline: 0;
            outline-offset: 0;
            background: url("data:image/svg+xml,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.5'%3E%3Cpath d='M8.0625 14.2598C11.6869 14.2598 14.625 11.3217 14.625 7.69727C14.625 4.0729 11.6869 1.13477 8.0625 1.13477C4.43813 1.13477 1.5 4.0729 1.5 7.69727C1.5 11.3217 4.43813 14.2598 8.0625 14.2598Z' stroke='%23929CA9' stroke-width='1.11111' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.7031 12.3379L16.5 16.1349' stroke='%23929CA9' stroke-width='1.11111' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E") no-repeat 10px center / 20px 20px;
            background-color: var(--white);
            border: 1px solid #E7F2FF;
            border-radius: 6px;
            color: var(--dark-blue);

        }

        &Box {
            display: none;
            background: var(--white);
            border: 1px solid var(--light-blue);
            border-radius: 6px;
            padding: 10px;
            position: absolute;
            top: 102%;
            left: 0;
            z-index: 6;
        }

        &Label {
            background: var(--white);
            border: 1px solid var(--light-blue);
            border-radius: 6px;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 8px 8px 8px 16px;

            &::after {
                transition: all .3s;
                margin-left: 10px;
                margin-top: 2px;
                content: "";
                display: block;
                width: 9px;
                height: 5px;
                background: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.04863 0.511601C9.04863 0.511601 9.07696 0.539928 9.13361 0.596582C9.19027 0.653236 9.21859 0.759089 9.21859 0.914141C9.21859 1.06919 9.16194 1.20337 9.04863 1.31668L5.04113 5.32418C4.92783 5.43749 4.79365 5.49414 4.63859 5.49414C4.48354 5.49414 4.34936 5.43749 4.23605 5.32418L0.228555 1.31668C0.115247 1.20337 0.0585938 1.06919 0.0585938 0.914141C0.0585938 0.759089 0.115247 0.624909 0.228555 0.511601C0.341862 0.398294 0.476042 0.34164 0.631094 0.34164H8.64609C8.80115 0.34164 8.93533 0.398294 9.04863 0.511601Z' fill='%231F2238'/%3E%3C/svg%3E") no-repeat center center / contain;
            }
        }

        &Field.open &Box {
            display: block;
        }

        &Field.open &Label {
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    &Search {
        position: relative;

        input {
            max-width: 212px;
            padding: 8px 16px 8px 41px;
            height: inherit;
            line-height: inherit;
            margin: 0;
            outline: 0;
            outline-offset: 0;
            background: url("data:image/svg+xml,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.5'%3E%3Cpath d='M8.0625 14.2598C11.6869 14.2598 14.625 11.3217 14.625 7.69727C14.625 4.0729 11.6869 1.13477 8.0625 1.13477C4.43813 1.13477 1.5 4.0729 1.5 7.69727C1.5 11.3217 4.43813 14.2598 8.0625 14.2598Z' stroke='%23929CA9' stroke-width='1.11111' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.7031 12.3379L16.5 16.1349' stroke='%23929CA9' stroke-width='1.11111' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E") no-repeat 10px center / 20px 20px;
            background-color: var(--white);
            border: 1px solid #E7F2FF;
            border-radius: 6px;
            color: var(--dark-blue);
        }

        &Items {
            display: none;
            position: absolute;
            top: 102%;
            left: 0;
            background: var(--white);
            border: 1px solid var(--light-blue);
            border-radius: 6px;
            padding: 10px;
            z-index: 5;
            margin: 0;
            list-style: none;
            width: 100%;

            li {
                margin-bottom: 5px;
            }

            &.open {
                display: block;
            }
        }
    }

    .tableMore {
        font-size: 18px;
    }

    &.play-none {
        .bonusesTablePlay {
            display: none;
        }
    }
}