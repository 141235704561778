.authorPage{
  padding-bottom: 50px;
  @include breakpoint(small down) {
    padding-bottom: 25px;
  }
&Wrapper{
  display: grid;
  grid-template-columns: auto 350px;
  gap: 20px;
  @include breakpoint(large down) {
    display: block;
  }
}
  &Main{
    background: var(--white);
    border-radius: 10px;
    padding: 25px 16px;
    @include breakpoint(large down) {
      margin-bottom: 38px;
      padding: 25px 20px;
    }
    @include breakpoint(small down) {
      margin-bottom: 13px;
    }
  }
  &Top{
    display: grid;
    grid-template-columns: 160px auto;
    gap: 32px;
    margin: 6px 17px 30px 0;
    padding: 5px 0 30px 0;
    border-bottom: 1px solid var(--secondary-color);
    @include breakpoint(large down) {
      padding: 5px 0 9px 0;
    }
    @include breakpoint(small down) {
      display: flex;
      flex-direction: column;
      padding: 5px 0 16px 0;
      margin: 0 17px 30px 9px;
    }
    h1{
      font-size: 28px;
      font-weight: 700;
      text-transform: none;
      margin-bottom: 0;
      @include breakpoint(small down) {
        text-align:  center;
      }
    }
    &Position{
      color: var(--bright-blue);
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 16px;
      @include breakpoint(small down) {
        text-align:  center;
      }
    }
    &Photo{
      border-radius: 50%;
      width: 160px;
      height: 160px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      @include breakpoint(small down) {
        margin: 0 auto;
      }
    }
    &Socials{
      display: flex;
      margin-bottom: 16px;
      gap: 5px;
      &Link{
        background-color: var(--dark-blue);
        border-radius: var(--light-border-radius);
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
      }
      @include breakpoint(small down) {
        justify-content: center;
      }
    }
  }
  &Favorite{
    display: flex;
    gap: 5px;
    margin-bottom: 16px;
  }
  h2{
    color: var(--dark-blue);
  }
  &Aside{
    &Wrapper{
      background: var(--white);
      border: 1px solid var(--light-blue);
      border-radius: var(--main-border-radius);
    }
    &Title{
      border-bottom: 1px solid var(--light-blue);
      padding: 14px 5px;
      text-align: center;
      text-transform: uppercase;
      font-size: 18px;
    }
    &Posts{
      padding: 16px 25px 32px;
      list-style: none;
      margin: 0;
      a{
        font-size: 18px;
        text-decoration: underline;
      }
    }
    &Post:not(:last-child){
      margin-bottom: 13px;
    }
  }
}