.read-more {
  margin-bottom: 25px;

  &-txt {
    @include breakpoint(medium down) {
      padding-top: 10px;
      padding-bottom: 5px;
    }
  }

  &-txt {
    &:not(.is-active) {
      overflow: hidden;
      max-height: 0;
    }
  }

  &-btn {
    margin: 0 auto;
    display: flex;

    &.hide {
      display: none;
    }
  }
}
