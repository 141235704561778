.contacts{
  padding-bottom: 100px;
  .breadcrumbs{
    margin: 0 0 16px 0;
    @include breakpoint(medium down) {
      margin: 0 0 16px 0;
    }
    ul{
      margin: 0;
    }
    .container{
      padding: 0;
    }
  }
  &Wrapper{
    display: grid;
    grid-template-columns: auto 350px;
    gap: 20px;
    @include breakpoint(medium down) {
      grid-template-columns: 100%;
    }
  }
  label{
    span{
      color: var(--red);
    }
  }
  input, textarea{
    display: block;
    width: 301px;
    height: 26px;
    padding: 5px;
    margin: 16px 0 15px 0;
    border: 1px solid var(--secondary-dark-color);
    border-radius: var(--light-border-radius);
    color: var(--dark-blue);
    @include breakpoint(small down) {
      max-width: 100%;
    }
  }
  textarea{
    height: 206px;
    resize: none;
    margin: 17px 0 16px 0;
  }
  input.invalid{
    border-color: var(--red);
  }
}