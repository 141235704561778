.toc {
    display: block;
    float: right;
    width: 340px;
    max-width: 100%;
    background: var(--white);
    border: 1px solid #CEE6FF;
    box-shadow: 0px 8px 14px rgba(52, 105, 179, 0.1);
    border-radius: 16px;
    padding: 11px;
    position: relative;
    margin-left: 18px;
    margin-bottom: 10px;

    @include breakpoint(small down) {
        float: none;
        width: 100%;
        margin: 25px 0;
    }

    &Wrapper {
        max-height: max-content;
        transition: all .3s;
        overflow-y: hidden;

        &.open {
            max-height: 1000px;
        }
    }

    &Button {
        border: none;
        cursor: pointer;
        background: transparent;
        padding: 5px;

        &Wrapper {
            text-align: center;
        }

        &.open {
            transform: rotate(-180deg);
        }

        img {
            display: block;
            width: 16px;
            height: 16px;
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    li {
        &.toc-3,
        &.toc-4,
        &.toc-5,
        &.toc-6 {
            display: none;
        }

        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        &::before {
            content: '';
            min-width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: var(--bright-blue);
            margin-right: 4px;
        }

        a {
            color: var(--dark-blue);
        }
        &.open {
            display: flex !important;
        }
    }
}

.tocFull {
    &Wrapper {
        max-height: 50px;
        overflow: hidden;
        transition: all .3s;
        border-radius: var(--main-border-radius);
        border: 1px solid var(--dark-blue);
        @include breakpoint(medium down) {
            max-height: 44px;
        }

        &.open {
            max-height: 2000px;
        }
    }

    &Title {
        background-color: var(--dark-blue);
        color: var(--white);
        height: 49px;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 18px;
        padding: 0 16px;
        font-weight: 700;
        @include breakpoint(medium down) {
            height: 43px;
            font-size: 16px;
        }

        &::before {
            content: "";
            display: block;
            width: 7px;
            height: 12px;
            background: url("data:image/svg+xml,%3Csvg width='8' height='13' viewBox='0 0 8 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.99243 6.12057C7.07156 6.1997 7.11113 6.2907 7.11113 6.39357C7.11113 6.49643 7.07156 6.58743 6.99243 6.66656L1.46128 12.1977C1.38215 12.2768 1.29116 12.3164 1.18829 12.3164C1.08542 12.3164 0.99442 12.2768 0.91529 12.1977L0.321819 11.6042C0.24269 11.5251 0.203125 11.4341 0.203125 11.3312C0.203125 11.2284 0.24269 11.1374 0.321819 11.0582L4.9865 6.39357L0.321819 1.72888C0.24269 1.64975 0.203125 1.55876 0.203125 1.45589C0.203125 1.35302 0.24269 1.26202 0.321819 1.18289L0.91529 0.58942C0.99442 0.51029 1.08542 0.470726 1.18829 0.470726C1.29116 0.470726 1.38215 0.51029 1.46128 0.58942L6.99243 6.12057Z' fill='%23A8FEB6'/%3E%3Cpath d='M6.99243 6.12057C7.07156 6.1997 7.11113 6.2907 7.11113 6.39357C7.11113 6.49643 7.07156 6.58743 6.99243 6.66656L1.46128 12.1977C1.38215 12.2768 1.29116 12.3164 1.18829 12.3164C1.08542 12.3164 0.99442 12.2768 0.91529 12.1977L0.321819 11.6042C0.24269 11.5251 0.203125 11.4341 0.203125 11.3312C0.203125 11.2284 0.24269 11.1374 0.321819 11.0582L4.9865 6.39357L0.321819 1.72888C0.24269 1.64975 0.203125 1.55876 0.203125 1.45589C0.203125 1.35302 0.24269 1.26202 0.321819 1.18289L0.91529 0.58942C0.99442 0.51029 1.08542 0.470726 1.18829 0.470726C1.29116 0.470726 1.38215 0.51029 1.46128 0.58942L6.99243 6.12057Z' fill='white'/%3E%3C/svg%3E") no-repeat center center / contain;
            transition: all .3s;
            margin-right: 9px;
            margin-top: 2px;
        }

        &.open {
            &::before {
                transform: rotate(90deg);
            }
        }
    }

    ul {
        padding: 0 20px;
        margin: 20px 0;
        list-style: none;
        counter-reset: item;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        @include breakpoint(medium down) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    li {
        display: flex;
        align-items: center;

        &::before {
            font-size: 13px;
            content: counter(item);
            counter-increment: item;
            min-width: 20px;
            height: 20px;
            border-radius: 50%;
            color: var(--white);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 6px;
            background-color: var(--bright-blue);
        }

        a {
            color: var(--bright-blue);
        }
    }
}