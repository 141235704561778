.top3{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;
  counter-reset: item;
  padding: 0 25px 36px;
  align-items: flex-end;
  @include breakpoint(large down) {
    grid-gap: 30px;
  }
  @include breakpoint(medium down) {
    padding: 0 0 32px;
  }
  @include breakpoint(small down) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
  &Logo{
    background-color: var(--dark-grey);
    width: 158px;
    margin: 0 auto;
    border: 4px solid var(--dark-grey);
    border-radius: var(--main-border-radius);
    overflow: hidden;
    height: 138px;
    margin-top: 5px;
    @include breakpoint(medium down) {
      width: 139px;
      height: 126px;
    }
    @include breakpoint(small down) {
      width: 158px;
      height: 138px;
    }
    img{
      max-width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
  &Bonus{
    background: var(--medium-white);
    border: 1px solid #E8F5FF;
    border-radius: 0px 0px 10px 10px;
    padding: 5px 20px;
    display: inline-block;
    font-weight: 700;
    color: var(--bright-blue);
    font-size: 22.5px;
    max-width: 220px;
    margin-bottom: auto;
    @include breakpoint(medium down) {
      font-size: 18px;
      max-width: 140px;
    }
    & > a,
    & > span{
      border-bottom: 1px dotted;
      display: inline-block;
      line-height: 17px;
      color: #6589B4;
      font-size: 13px;
      font-weight: 400;
      text-decoration: dotted;
      @include breakpoint(medium down) {
        font-size: 12px;
        line-height: 13px;
      }
    }
  }
  &Item{
    position: relative;
    background-color: var(--white);
    box-shadow: 0px 8px 14px rgba(52, 105, 179, 0.1);
    border-radius: 16px;
    padding: 0 11px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    line-height: 25px;
    min-height: 400px;
    &::after{
      position: absolute;
      content: counter(item);
      counter-increment: item;
      top: 11px;
      left: 6px;
      font-size: 18px;
      color: var(--white);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: var(--light-grey);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:first-child{
      order: 2;
      min-height: 415px;
      @include breakpoint(medium down) {
        margin-bottom: 7px;
      }
      @include breakpoint(small down) {
        margin-bottom: 0;
      }
    }
    &:nth-child(2){
      order: 1;
      .top3BonusMore{
        display: none;
      }
    }
    &:nth-child(3){
      order: 3;
      .top3BonusMore{
        display: none;
      }
    }
    & .button{
      width: 192px;
      height: 38px;
    }
    &.bg-avial{
      .button{
        background-color: var(--secondary-color);
        box-shadow: 0px 3px 0px #868F9A;
        width: 192px;
        height: 38px;
      }
    }
    &.bg-blue::after{
      background-color: var(--bright-blue);
    }
  }
  &Link{
    padding: 10px 0;
  }
  &Info{
    border-top: 1px solid var(--light-blue);
    padding: 7px 0;
    width: 100%;
    font-size: 17.5px;
    &.plays{
      font-size: 25px;
      font-weight: 700;
    }
    &.deposits {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      gap: 10px;
      & > div {
        display: flex;
        gap: 10px;
      }
    }
    a{
      font-size: 19px;
    }
  }
}